import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  paper: {
    minWidth: 650,
    maxWidth: 650,
    boxShadow: '-4px 0px 10px 0px rgba(121,145,173,0.2) !important',
    padding: '32px 32px',
    display: 'flex',
    '& .item:not(:first-child)': {
      margin: theme.spacing(1, 0)
    },
    '& input, .MuiSelect-select, .MuiInputBase-root': {
      minHeight: 35,
      height: 35,
      marginTop: 0
    },
  },
  childrenContainer: {
    marginTop: theme.spacing(3),
  },
}));

export default styles;
